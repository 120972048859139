<template>
  <div>
    <b-modal
      id="create-tenant-subscription-modal"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="lg"
      title="CREATE TENANT SUBSCRIPTION"
      centered
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(handleSubmit)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                class="required"
                label="Businesses"
                label-for="businesses"
              >
                <v-select
                  v-model="form.tenant_id"
                  :options="tenants"
                  :reduce="option => option.id"
                  label="name"
                  placeholder="Select Businesses"
                  multiple
                  deselect-from-dropdown
                >
                  <template
                    v-slot:option="option"
                    class="text-truncate"
                  >
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              class="d-flex justify-content-end align-items-center mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="w-100"
                type="submit"
                variant="primary"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SubscriptionRepository = RepositoryFactory.get('subscription')
const ClientRepository = RepositoryFactory.get('client')

function initialState() {
  return {
    open: false,
    packageId: null,
    loading: false,
    tenants: [],
    form: {},
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BRow,
    BOverlay,
    vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(packageId) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.packageId = packageId
      this.getClientListForDropdown()
    },
    async getClientListForDropdown() {
      this.loading = true
      try {
        const { data } = await ClientRepository.getClientListForDropdown()
        this.tenants = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async handleSubmit() {
      try {
        this.loading = true
        const payload = {
          tenant_id: this.form.tenant_id,
          package_id: this.packageId,
        }
        const res = await SubscriptionRepository.createTenantSubscription(payload)
        if (res.status === 200) {
          this.open = false
          this.showSuccessMessage('Subscription Created Successfully')
        } else {
          this.showErrorMessage('Cannot Create Subscription')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped>

</style>
