<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Package Name"
            label-for="[package]"
          >
            <b-form-input
              id="[package]"
              v-model="filters['package_name']"
              placeholder="Search"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :to="{ name: 'add-package' }"
          variant="outline-primary"
          class="mb-2"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Add New Package
        </b-button>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-row>
        <b-col>
          <b-overlay
            id="overlay-background"
            :show="loading"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
            >
              <template #cell(client_name)="data">
                <div
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    v-if="data.item.logo === null"
                    :text="avatarText(data.value)"
                    size="32"
                    variant="light-primary"
                  />
                  <b-avatar
                    v-else
                    :src="`${data.item.logo}`"
                    size="32"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1 text-primary"
                    >
                      {{ data.value }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(is_active)="data">
                <b-badge
                  :variant="`light-${data.value ? 'success' : 'danger'}`"
                >
                  {{ data.value ? 'Active' : 'Inactive' }}
                </b-badge>
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>
              <template #cell(start_date)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>
              <template #cell(end_date)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>
              <template #cell(package_description)="data">
                <!-- eslint-disable vue/no-v-html-->
                <span v-html="data.value" />
              </template>
              package_description
              <template #cell(action)="data">
                {{ data.value }}
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :to="{ name: 'view-package', params: { id: data.item.id } }"
                  >
                    <feather-icon icon="EyeIcon" />
                    View
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name: 'edit-package', params: { id: data.item.id } }"
                  >
                    <feather-icon icon="EditIcon" />
                    Edit
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75 mr-1">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <create-tenant-subscription-modal ref="create_tenant_subscription_modal" />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BAvatar,
  BPagination,
  BTable,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Filter from '@core/mixins/filter'
import 'swiper/css/swiper.css'
import { avatarText } from '@core/utils/filter'
import CreateTenantSubscriptionModal from '@/components/Packages/CreateTenantSubscriptionModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const packageRepository = RepositoryFactory.get('package')

export default {
  directives: {
    Ripple,
  },
  components: {
    BCol,
    BRow,
    BCard,
    BAvatar,
    BPagination,
    BTable,
    BOverlay,
    BDropdown,
    BCardActions,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BBadge,
    BButton,
    vSelect,
    CreateTenantSubscriptionModal,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      total: 0,
      meta: {},
      loading: false,
      sort: '',
      value: 25,
      perPage: 20,
      pageOptions: [10, 20, 50],
      page: 1,
      filters: {},
      rows: [],
      statusOptions: [
        {
          key: '1',
          name: 'Active',
        },
        {
          key: '0',
          name: 'Inactive',
        },
      ],
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'package_name',
          label: 'Package Name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'package_description',
          label: 'Description',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'start_date',
          label: 'Start Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'end_date',
          label: 'End Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'grace_period',
          label: 'Grace Days',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'is_active',
          label: 'Active',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'Created Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getPackageList()
      },
    },
    page() {
      this.getPackageList()
    },
    perPage() {
      this.getPackageList()
    },
  },
  methods: {
    async getPackageList() {
      this.loading = true
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, ['filter[created_at]'])
        const { data } = (await packageRepository.getPackageList(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getPackageListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, ['filter[created_at]'])
        const { data } = (await packageRepository.getPackageListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    createTenantSubscription(id) {
      this.$refs.create_tenant_subscription_modal.openModal(id)
    },
    filterQueryUpdate() {
      this.getPackageList()
    },
    handleChangePage(page) {
      this.page = page
      this.getPackageList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.getPackageList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
